export type BbsPostStateKey = 'ACTIVE' | 'HIDDEN' | 'DELETE';
export const BbsPostState: Record<BbsPostStateKey, string> = {
  ACTIVE: '정상',
  HIDDEN: '숨김',
  DELETE: '삭제',
};

export type BbsSortTypeKey = 'SEEN' | 'RECENT' | 'REPLY';
export const BbsSortType: Record<BbsSortTypeKey, string> = {
  RECENT: '최신순',
  SEEN: '조회순',
  REPLY: '댓글순',
};

export type BbsQueryFieldKey = 'ALL' | 'TITLE' | 'WRITE_USER_NAME';
export const BbsQueryField: Record<BbsQueryFieldKey, string> = {
  ALL: '전체',
  TITLE: '제목',
  WRITE_USER_NAME: '작성자',
};
