import { type ApiHelper } from '@ocodelib/api-common';

type ApiClass<TApi> = new (helper: ApiHelper) => TApi;

export function createLazyApiGenerator(owner: { helper: ApiHelper }) {
  const fieldNameStore = new Map<any, string>();

  const getOrCreateFieldName = (key: any) => {
    for (let i = 0; i < 100; i++) {
      const fieldName = '__f' + String(Math.random()).substring(2);
      if (!fieldNameStore.has(fieldName)) {
        fieldNameStore.set(key, fieldName);
        return fieldName;
      }
    }
    throw new Error('create random field name failed');
  };

  return function createLazyApi<TApi>(cls: ApiClass<TApi>): TApi {
    const prop = getOrCreateFieldName(cls);
    if (prop in owner) {
      return (owner as any)[prop] as TApi;
    }
    const instance = new cls(owner.helper);
    Object.defineProperty(owner, prop, {
      enumerable: false,
      configurable: false,
      writable: false,
      value: instance,
    });
    // owner[prop] = instance;
    return instance;
  };
}
